<template>
    <div id="content" class="notifications">
        <div class="bar-actions">
            <Button bType="cancel_changes" :bCallback="goBack" customClass="btn-spaced"></Button>
            <Button
                bType="save"
                :bCallback="save"
                :bLabel="'user.form.save'"
                :customClass="{
                    'btn-action': true
                }"
            ></Button>
        </div>
        <div class="notifications-box">
            <div class="form">
                <div class="info">
                    <div class="label">{{ $t('notifications.noti_via_mail') }}</div>
                    <div class="description">
                        {{ $t('notifications.description') }} (<b>{{ user.email }}</b
                        >)
                    </div>
                </div>

                <div class="items">
                    <template v-for="(notification, tool) in notifications">
                        <div class="item" :class="{ inactive: notification.periodicity == -1 }" :key="tool" v-if="visibleTools.includes(parseInt(tool))">
                            <div class="meta">
                                <div class="label">{{ $t('notifications.tools.' + tool) }}</div>
                                <Input :gOptions="{ returnObject: false, itemValue: 'value', itemText: 'text' }" gType="select" v-model="notification.periodicity" :gItems="Object.values(periodicity)" gSize="m"></Input>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TOOLS } from '@/constants'

export default {
    name: 'Notifications',
    data() {
        return {
            currentPage: 'notifications',
            notifications: {}
        }
    },
    computed: {
        user() {
            return this.$store.getters['login/getLocalEmployee']
        },

        visibleTools() {
            var visible = []
            if (localStorage.config) {
                var config = JSON.parse(localStorage.config)
                if (config.tools) {
                    visible = config.tools
                }
            }
            return visible
        },

        getNotifications() {
            var notifications = this.$store.getters['employee/getNotifications']
            var availableNotifications = {
                [TOOLS.LABELS]: { periodicity: -1 }
                // [TOOLS.CHECKLISTS]: { periodicity: -1 }
            }
            if (notifications) {
                for (const notification of Object.values(notifications)) {
                    availableNotifications[notification.tool].periodicity = notification.periodicity
                }
            }
            return availableNotifications
        },

        periodicity() {
            return [
                { text: this.$t('notifications.i_dont_want'), value: -1 },
                { text: this.$t('template.general.periodicity_times_label.daily'), value: 1 },
                { text: this.$t('template.general.periodicity_times_label.weekly'), value: 2 },
                { text: this.$t('template.general.periodicity_times_label.biweekly'), value: 3 },
                { text: this.$t('template.general.periodicity_times_label.monthly'), value: 4 }
                // { text: this.$t('template.general.periodicity_times_label.quarterly'), value: 5 }
            ]
        }
    },
    methods: {
        load() {
            var self = this

            this.$store.dispatch('employee/loadNotifications').then(function () {
                self.notifications = { ...self.getNotifications }
            })
        },

        save() {
            var self = this
            this.$store.dispatch('employee/editNotifications', { data: JSON.stringify(this.notifications) }).then(function (response) {
                if (response) {
                    self.notifications = { ...self.getNotifications }
                    self.$snackbar.open({
                        message: self.$t('snackbar.save_success'),
                        customClass: 'success',
                        duration: 5000
                    })
                }
            })
        },
        goBack() {
            location.reload()
        }
    },
    created() {
        this.load()
    }
}
</script>

<style lang="scss">
#content.notifications {
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;

    .bar-actions {
        .btn-cancel_changes {
            margin-right: 0;
        }
    }

    .notifications-box {
        @include display-flex();
        @include flex-direction(column);
        align-items: center;
        @include box-shadow($h: 0px, $v: 0px, $b: 12px, $c: rgba($color-neutral-600, 0.1));
        @include border-radius(3px);
        overflow: hidden;
        width: 100%;
        background-color: $color-neutral-50;
        // height: calc(100% - (63px + 23px));
        height: calc(100% - 76px);
        display: flex;
        margin-top: 76px;

        .form {
            padding-top: 2em;
            width: 1000px;
        }

        .info {
            width: 100%;
            margin: 30px 40px;

            .label {
                @include font-size('ml');
                color: $color-black;
                font-family: $text-bold;
            }

            .description {
                @include font-size('s');
                color: $color-neutral-600;
                font-family: $text-medium;

                b {
                    font-size: inherit;
                    color: inherit;
                    font-family: inherit;
                }
            }
        }

        .items {
            @include display-flex();
            @include align-items();
            @include align-content();
            @include flex-direction(column);
            width: 80%;
            margin: 0 auto;

            .item {
                @include display-flex();
                @include align-items();
                @include background($image: img('bell_second.svg'), $size: 20px, $position: left center);
                @include align-content();
                width: 100%;
                padding-left: 35px;
                margin: 10px auto;

                .meta {
                    @include display-flex();
                    @include align-items();
                    @include border-radius(3px);
                    @include box-shadow($h: 0px, $v: 0px, $b: 9px, $c: rgba($color-neutral-600, 0.1));
                    padding: 9px 12px;
                    width: 100%;
                    background-color: #fff;
                    min-height: 40px;

                    .label {
                        @include display-inline-flex();
                        @include font-size('sm');
                        width: calc(100% - 200px);
                        color: $color-black;
                        font-family: $text-bold;
                    }

                    .input-container {
                        @include display-inline-flex();
                        max-width: 260px;
                        margin: 0;
                    }
                }

                &.inactive {
                    @include background($image: img('bell_disabled_inactive.svg'), $size: 20px, $position: left center);
                    box-shadow: unset;

                    .meta {
                        background-color: $color-neutral-200;

                        .label {
                            color: $color-neutral-600;
                        }
                    }
                }
            }
        }
    }
}
</style>
